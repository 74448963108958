import React, { Fragment } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import {AppStore, BaseUpdate} from 'models';
import Styles from './Styles';
import { formatDate } from 'helpers/formats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { startRollout } from '../../redux/actions/updates';

export interface QueuedUpdatesProps extends RouteComponentProps {
  scheduledUpdates: BaseUpdate[]
  startRollout: ({ data: { _links: { self: string } } }) => void;
}

const QueuedUpdates: React.FunctionComponent<QueuedUpdatesProps> = props => {

  const handleCreate = () => {
    props['history'].push('/schedule-update');
  }

  const handleStartRollout = (item) => {
    const { startRollout } = props;
    let data = { data: { _links: { self: item._links.self.href } } };
    startRollout(data);
  };

  return (
    <div style={Styles.mainContainer}>
      <div style={Styles.header}>
        <div style={Styles.title}>Queued</div>
        <div style={Styles.title}>
          <div style={{ marginRight: 8 }}>Schedule Updates</div>
          <div style={Styles.button} onClick={handleCreate}>
            <FontAwesomeIcon
              icon={faPlus}
              style={Styles.icon}
            />
          </div>
        </div>
      </div>
      <div style={Styles.tableContainer}>
        <div>
          {props.scheduledUpdates &&
            props.scheduledUpdates.length > 0 ? (
              props.scheduledUpdates.map((item, index) =>
                <Fragment key={index}>
                  <div style={Styles.row}>
                    <div style={Styles.cellBolder}>{item.name}</div>
                    <div style={Styles.cellBolder}>{item.groupName}</div>
                    <div style={Styles.cellBolder}>{item.status}</div>
                    <div style={Styles.cell}>{item.startAt ? formatDate(item.startAt) : 'N/A'}</div>
                    { item.status === "ready" &&
                       <div style={Styles.button} onClick={() => handleStartRollout(item) }>Run</div> }
                    { item.status === "finished" &&
                       <div style={Styles.buttonFinished}></div> }
                    { item.status === "paused" &&
                       <div style={Styles.buttonPaused}></div> }
                  </div>
                  <div style={Styles.separatorHorizontal}></div>
                </Fragment>
              )
            ) : (
              <>
                <div>
                  <p style={Styles.noQueuedUpdates}>There are no queued updates at the moment</p>
                </div>
              </>)
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppStore) => ({

});

const mapDispatchToProps = dispatch => ({
  startRollout: (data) => dispatch(startRollout(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QueuedUpdates) as React.ComponentType<any>);