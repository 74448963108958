import * as actions from "../constants/roasters";
import { OTA } from "api";
import { BaseRoaster } from "models";

export const getRoasters = ({ limit, offset, query }) => (dispatch) => {
  dispatch({ type: actions.GET_ROASTERS });
  OTA.get<BaseRoaster[]>(`/roasters?limit=${limit}&offset=${offset}&query=${query}`)
    .then((response) => {
      dispatch({ type: actions.GET_ROASTERS_SUCCESS, data: response });
    })
    .catch((error) => {
      dispatch({ type: actions.GET_ROASTERS_FAILED, error: error });
    });
};

export const setSelectedRoaster = (selectedRoaster) => (dispatch) => {
  dispatch({ type: actions.SET_SELECTED_ROASTER, data: { selectedRoaster } });
};

export const getRoasterActions = (roasterId) => (dispatch) => {
  dispatch({ type: actions.GET_ROASTER_ACTIONS });

  OTA.get(`/roasters/${roasterId}`)
    .then((response) => {
      dispatch({ type: actions.GET_ROASTER_ACTIONS_SUCCESS, data: response });
    })
    .catch((error) => {
      dispatch({ type: actions.GET_ROASTER_ACTIONS_FAILURE, error: error });
    });
};
