import { DistributionsReducer } from "../../models";
import * as actions from '../constants/distributions';

const initialState: DistributionsReducer = {
  isLoading: false,
  distributionSets: [],
  error: null
};
const updates = (state: DistributionsReducer = initialState, action: { type: string, data?: any }) => {
  const { type, data } = action;
  switch(type) {
    case actions.GET_DISTRIBUTION_SETS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_DISTRIBUTION_SETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        distributionSets: data.distributionSets.data.content
      };
    case actions.GET_DISTRIBUTION_SETS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: data
      };
    default:
      return state;
  }
};

export default updates;
