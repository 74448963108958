import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: 30,
  } as CSSProperties,
  subContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginRight: 60,
  } as CSSProperties,
  appVersion: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

export default styles;
