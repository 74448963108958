import * as actions from '../constants/distributions';
import { BaseDistribution } from 'models';
import { OTA } from 'api';

export const getDistributions = () => dispatch => {
  dispatch({ type: actions.GET_DISTRIBUTION_SETS })
  OTA.get<BaseDistribution[]>(`/distributions`)
    .then(distributionSets => dispatch({ type: actions.GET_DISTRIBUTION_SETS_SUCCESS, data: { distributionSets } }))
    .catch(error => dispatch({ type: actions.GET_DISTRIBUTION_SETS_FAILED }))
}
