import React, { useState, useEffect } from "react";
import Button from "@atlaskit/button";
import { RouteComponentProps } from "react-router";
import { BaseRoaster } from "models";
import Styles from "./Styles";

export interface SelectedRoasterProps extends RouteComponentProps {
  selectedRoasters: BaseRoaster[];
  setSelectedRoasters: (roaster) => void;
}

const SelectedRoaster: React.FunctionComponent<SelectedRoasterProps> = (
  props
) => {
  const [selectedRoasters, setSelectedRoasters] = useState([]);

  useEffect(() => {
    setSelectedRoasters(props.selectedRoasters);
  }, [props.selectedRoasters]);

  return (
    <>
      <div style={Styles.mainContainer}>
        <p style={Styles.titlePurple}>Selected Roasters</p>
        <div style={Styles.buttonGroup}>
          {selectedRoasters?.map((item, index) => {
            return (
              <Button
                key={index}
                style={Styles.selectionButtonSelected}
                onClick={() => props.setSelectedRoasters(item)}
                title={item.name}
                appearance="primary"
              >
                {item.name}
              </Button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SelectedRoaster as React.ComponentType<any>;
