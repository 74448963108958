import React, { useEffect, useState } from "react";
import Pagination from "@atlaskit/pagination";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { AppStore, BaseRoaster } from "models";
import Topbar from "components/Topbar";
import Styles from "./Styles";
import RoastersTable from "components/RoastersTable";
import { getRoasters } from "redux/actions/roasters";
import Spinner from "components/Spinner";
import RoasterDetails from "components/RoasterDetails";

export interface MachineListProps extends RouteComponentProps {
  isLoading: boolean;
  roasters: [BaseRoaster[]];
  roasterCount: number;
  getRoasters: ({ limit, offset, query }) => void;
  auth: { [key: string]: string };
}

const MachineList: React.FunctionComponent<MachineListProps> = (props) => {
  const [Pages, setPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const limit = 20; // will be hardcoded for now.

  useEffect(() => {
    const start = props.roasterCount / limit;
    const arr = [];

    if (start >= 1) {
      for (let i = 0; i < start; i++) {
        arr[i] = i + 1;
        setPages(arr);
      }
    } else {
      setPages([1]);
    }
  }, [props.roasterCount]);

  useEffect(() => {
      return fetchRoasters("");
  }, [offset]);
  
  const fetchRoasters = (query) => {
    const getRoasters = props.getRoasters;
    getRoasters({ limit, offset, query });
  }

  const handleChange = (e, page) => {
    const newOffset = limit * (page - 1);
    setOffset(newOffset);
    setCurrentPage(page);
  };

  return (
    <>
      <Topbar auth={props.auth} />
      {props.isLoading ? (
        <div style={{ display: "flex", height: "80vh" }}>
          <Spinner size={100} />
        </div>
      ) : (
        <div>
          <div style={Styles.mainContainer}>
            <div style={Styles.leftContainer}>
              <RoastersTable
                roasters={props.roasters}
                fetchRoasters={fetchRoasters}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
              />
              <div style={Styles.pagination}>
                <Pagination
                  pages={Pages}
                  onChange={(e, page) => handleChange(e, page)}
                  selectedIndex={currentPage - 1}
                />
              </div>
            </div>
            <div style={Styles.rightContainer}>
              <RoasterDetails roasters={props.roasters} />
            </div>
          </div>
        </div>
      )}
      <div style={Styles.appVersion}>
        <p>APP_VERSION: {process.env.REACT_APP_VERSION}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  isLoading: state.roasters.isLoading,
  roasters: state.roasters.roasters,
  roasterCount: state.roasters.roasterCount,
});

const mapDispatchToProps = (dispatch) => ({
  getRoasters: ({ limit, offset, query }) => dispatch(getRoasters({ limit, offset, query })),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MachineList) as React.ComponentType<any>
);
