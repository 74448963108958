import * as actions from '../constants/updates';
import { OTA } from 'api';
import {BaseUpdate} from "../../models";

export const getQueuedUpdates = () => dispatch => {
  dispatch({ type: actions.GET_QUEUED_UPDATES });
  OTA.get<BaseUpdate[]>(`/updates/queued`)
    .then(updates => {
      return dispatch({ type: actions.GET_QUEUED_UPDATES_SUCCESS, data: { updates } })
    })
    .catch(error => {
      return dispatch({ type: actions.GET_QUEUED_UPDATES_FAILED, error: error.toString() })
    });
};

export const createUpdate = (data) => dispatch => {
  dispatch({ type: actions.CREATE_UPDATE })
  OTA.post<any>(`/rollouts`, data)
    .then(result => { dispatch({ type: actions.CREATE_UPDATE_SUCCESS, data: { result } })})
    .catch(error => { dispatch({ type: actions.CREATE_UPDATE_FAILED, data: error.data.message })})
}

export const startRollout = (data) => dispatch => {
  dispatch({ type: actions.START_ROLLOUT})
  OTA.post<any>(`/rollouts/start`, data)
    .then(result => { dispatch({ type: actions.START_ROLLOUT_SUCCESS, data: { result } })})
    .catch(error => { dispatch({ type: actions.START_ROLLOUT_FAILED, data: error.data.message })})
}