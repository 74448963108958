import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "spaceEvenly",
    height: "85vh",
    marginLeft: 30,
    marginRight: 30,
  } as CSSProperties,
  leftContainer: {
    width: "100%",
    marginRight: 30,
  } as CSSProperties,
  rightContainer: {
    width: "100%",
    marginTop: 66,
  } as CSSProperties,
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  appVersion: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
};

export default styles;
