import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppStore } from 'models';
import Topbar from 'components/Topbar';

export interface CreatePackageProps extends RouteComponentProps {
  auth: {[key:string]: string}
}

const CreatePackage: React.FunctionComponent<CreatePackageProps> = props => {

  return (
    <>
      <Topbar auth={props.auth}/>
      <div>
        Create Package
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  
});
const mapDispatchToProps = dispatch => ({
  
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatePackage) as React.ComponentType<any>);