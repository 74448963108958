import React, { useEffect, useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import moment from "moment";
import { AppStore, BaseDistribution, BaseRoaster } from "models";
import Styles from "./Styles";
import { getDistributions } from "redux/actions/distributions";
import Button from "@atlaskit/button";
import ScheduleModal from "../ScheduleModal";
import { formatDate } from "../../helpers/formats";
import Spinner from "../Spinner";

export interface SelectUpdateProps extends RouteComponentProps {
	distributionSets: BaseDistribution[];
	getDistributions: () => void;
	selectedRoasters: BaseRoaster[];
	isLoadingDistributionSets: boolean;
}

const SelectUpdate: React.FunctionComponent<SelectUpdateProps> = (props) => {
	const [filteredRoasters, setFilteredRoasters] = useState([]);
	const [distributionSets, setDistributionSets] = useState([]);
	const [openScheduleModal, setScheduleModal] = useState(false);
	const [notEmptyValues, setNotEmptyValues] = useState(false);

	useEffect(() => {
		const getDistributions = props.getDistributions;
		getDistributions();
	}, [props.getDistributions]);

	useEffect(() => {
		if (props.distributionSets.length > 0) {
			const distClone = props.distributionSets;
			const firmwareList = [];
			const firmwareFilter = [];
			const rPiFilter = [];
			const rPiList = [];
			distClone.map((each) => {
				if (each.name.includes("RaspberryApps")) {
					return rPiFilter.push(each);
				} else if (each.name.includes("Firmware")) {
					return firmwareFilter.push(each);
				}
			});

			rPiFilter.sort((a, b) => b.createdAt - a.createdAt);
			firmwareFilter.sort((a, b) => b.createdAt - a.createdAt);

			if (firmwareFilter.length > 0) {
				firmwareList.push({
					name: "Firmware",
					version: firmwareFilter[0]?.version,
					id: firmwareFilter[0]?.id,
				});
			}

			if (rPiFilter.length > 0) {
				rPiList.push({
					name: "Raspberry App",
					version: rPiFilter[0]?.version,
					odd: rPiFilter[0]?.name,
					oddID: rPiFilter[0]?.id,
					even: rPiFilter[1]?.name,
					evenId: rPiFilter[1]?.id,
				});
			}

			setDistributionSets([...firmwareList, ...rPiList]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.distributionSets.length]);

	useEffect(() => {
		distributionSets.filter((each) => each.selected).length > 0 && props.selectedRoasters.length > 0
			? setNotEmptyValues(true)
			: setNotEmptyValues(false);
	}, [props.selectedRoasters, distributionSets]);

	const selectItemUpdate = (item) => {
		const cloneUpdates = cloneDeep(distributionSets);
		cloneUpdates.map((each) =>
			item.id === each.id ? (each.selected = !item.selected) : (each.selected = false)
		);
		setDistributionSets(cloneUpdates);
	};

	const handleConfirm = () => {
		const parsedRosterList = props.selectedRoasters.filter(
			(each) => each.DS_version !== distributionSets.filter((each) => each.selected)[0].version
		);

		setFilteredRoasters(parsedRosterList);
		if (props.selectedRoasters.length !== parsedRosterList.length) {
			if (parsedRosterList.length < 1) {
				return props.selectedRoasters.length < 2
					? toast.warn("This device is already up to date")
					: toast.warn("These devices are already up to date");
			} else setScheduleModal(true);
		} else setScheduleModal(true);
	};

	const handleCloseScheduleModal = () => {
		setScheduleModal(false);
	};

	return (
		<>
			{props.isLoadingDistributionSets ? (
				<div style={{ display: "flex", height: "100vh", width: "30vw" }}>
					<Spinner size={100} />
				</div>
			) : (
				<div style={Styles.mainContainer}>
					<div style={Styles.titlePurple}>Select Update</div>
					<div style={Styles.updatesContainers}>
						{distributionSets.map((item, index) => (
							<Button
								key={index}
								style={item.selected ? Styles.selectionButtonSelected : Styles.selectionButton}
								onClick={() => selectItemUpdate(item)}
								title={item.name}
								appearance={item.selected ? "primary" : "default"}>
								<div style={{ display: "flex", flexDirection: "column" }}>
									<p style={{ lineHeight: 1.4 }}>{item.name}</p>
									<sub style={{ lineHeight: 1.4 }}>{item.version}</sub>
								</div>
							</Button>
						))}
					</div>
					<div style={Styles.titlePurple}>Schedule Update</div>
					<div style={Styles.dateContainer}>{formatDate(moment())}</div>
					<div style={Styles.buttonContainer}>
						<Button
							appearance="primary"
							isDisabled={!notEmptyValues}
							style={
								notEmptyValues
									? Styles.buttonConfirm
									: { ...Styles.buttonConfirm, backgroundColor: "rgba(119,118,222,0.69)" }
							}
							onClick={() => handleConfirm()}>
							Confirm Update
						</Button>
					</div>
					{openScheduleModal && (
						<ScheduleModal
							open={openScheduleModal}
							close={handleCloseScheduleModal}
							selectedUpdate={distributionSets.filter((each) => each.selected)[0]}
							selectedDate={moment()}
							selectedGroup={filteredRoasters}
						/>
					)}
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: AppStore) => ({
	distributionSets: state.distributions.distributionSets,
	isLoadingDistributionSets: state.distributions.isLoading,
});
const mapDispatchToProps = (dispatch) => ({
	getDistributions: () => dispatch(getDistributions()),
});
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SelectUpdate) as React.ComponentType<any>
);
