import { UpdatesReducer } from "../../models";
import * as actions from '../constants/updates';

const initialState: UpdatesReducer = {
  isLoading: false,
  updates: [],
  create: null,
  queuedUpdates: [],
  error: ""

};
const updates = (state: UpdatesReducer = initialState, action: { type: string, data?: any }) => {
  const { type, data } = action;
  switch(type) {
    case actions.GET_QUEUED_UPDATES:
      return {
        ...state,
        isLoading: true,
        create: null
      };
    case actions.GET_QUEUED_UPDATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        queuedUpdates: data.updates.data
      };
    case actions.GET_QUEUED_UPDATES_FAILED:
      return {
        ...state,
        isLoading: false,
        error: data
      };
    case actions.CREATE_UPDATE:
      return {
        ...state,
        isLoading: true,
        create: false
      };
    case actions.CREATE_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: data
      };
    case actions.CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        create: true
      };
    case actions.START_ROLLOUT:
      return {
        ...state,
      };
    case actions.START_ROLLOUT_SUCCESS:
      return {
        ...state,
      };
    case actions.START_ROLLOUT_FAILED:
      return {
        ...state,
        error: data
      };
    default:
      return state;
  }
};

export default updates;
