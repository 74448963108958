import { CSSProperties } from 'react';

export const styles = {
  mainContainer: {
    height: '100%',
    overflow: 'auto',
    backgroundColor: '#F4F5F9',
    padding: 20
  } as CSSProperties,
  title: {
    color: '#7876E0',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 19
  } as CSSProperties,
  subTitle: {
    color: '#3B3B3B',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 11
  } as CSSProperties,
  line: {
    height: 1,
    backgroundColor: '#3B3B3B',
    marginBottom: 22
  } as CSSProperties,
  squaresContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 23
  } as CSSProperties,
  square: {
    backgroundColor: '#3B3B3B',
    color: '#FFF',
    height: 61,
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 20,
    justifyContent: 'center',
    borderRadius: 3
  } as CSSProperties,
  squareVersion: {
    textAlign: 'center'
  } as CSSProperties,
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 35
  } as CSSProperties,
  cell: {
    width: 231,
    fontSize: 16,
    color: '#414042',
    textAlign: 'center'
  } as CSSProperties,
  separator: {
    width: 1,
    height: 31,
    backgroundColor: '#414042'
  } as CSSProperties,
  separatorHorizontal: {
    height: 1,
    backgroundColor: '#3B3B3B',
  } as CSSProperties,
}

export default styles;