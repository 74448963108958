import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    width: "100%",
    height: 600,
    backgroundColor: "#F4F5F9",
    borderRadius: 3,
    padding: 19,
    margin: 15,
  } as CSSProperties,
  titleBlack: {
    color: "#3B3B3B",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 13,
  } as CSSProperties,
  titlePurple: {
    color: "#7876E0",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
  } as CSSProperties,
  subtitle: {
    color: "#3B3B3B",
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 13,
  } as CSSProperties,
  groupsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 42,
  } as CSSProperties,
  selectionButton: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    background: "#FFF",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 16,
    marginBottom: 18,
    border: "2px solid #7876E0",
  } as CSSProperties,
  selectionButtonSelected: {
    display: "flex",
    justifyContent: "center",
    height: 40,
    background: "#7876E0",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 16,
    marginBottom: 18,
    border: "2px solid #7876E0",
  } as CSSProperties,
  buttonsGenericContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: 200,
  } as CSSProperties,
  buttonsCustomContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  } as CSSProperties,
  buttonNewGroup: {
    backgroundColor: "#FFFFFF",
    width: 128,
    height: 34,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: 3,
    cursor: "pointer",
  } as CSSProperties,
  buttonPlus: {
    width: 18,
    height: 18,
    backgroundColor: "#7876E0",
    borderRadius: "50%",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  } as CSSProperties,
  icon: {
    fontSize: "15px",
    color: "black",
    padding: "5px",
    cursor: "pointer"
  } as CSSProperties,
  buttonText: {
    fontSize: 14,
    color: "#3B3B3B",
  } as CSSProperties,
  selectRoastersTitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 21,
  } as CSSProperties,
  searchContainer: {
    width: 194,
  } as CSSProperties,
};

export default styles;
