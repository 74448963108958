import { GroupsReducer } from "../../models";
import * as actions from '../constants/groups';

const initialState: GroupsReducer = {
  isLoading: false,
  groups: [],
  create: null
};
const groups = (state: GroupsReducer = initialState, action: { type: string, data?: any }) => {
  const { type, data } = action;
  switch(type) {
    case actions.GET_GROUPS:
      return {
        ...state,
        isLoading: true,
        create: null
      };
    case actions.GET_GROUPS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_GROUPS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        groups: [...data.groups],
      };
    case actions.CREATE_GROUP:
      return {
        ...state,
        isLoading: true,
        create: null
      };
    case actions.CREATE_GROUP_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        create: data.create
      };
    default:
      return state;
  }
}
export default groups;
