import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import appState from '../reducers';

export default function () {
  const store = createStore(appState, composeWithDevTools(
    applyMiddleware(thunk),
  ));
  return { store };
};
