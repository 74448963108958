import { CSSProperties } from 'react';

export const styles = {
  mainContainer: {
    width: 840,
    height: '85vh',
    backgroundColor: '#F4F5F9',
    borderRadius: 3,
    padding: 19,
    overflowY: 'scroll'
  } as CSSProperties,
  header: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    marginBottom: 28
  } as CSSProperties,
  title: {
    color: '#7876E0',
    fontSize: 16,
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  } as CSSProperties,
  button: {
    width: 32,
    height: 32,
    backgroundColor: '#7876E0',
    borderRadius: '50%',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  } as CSSProperties,
  buttonFinished: {
    width: 32,
    height: 32,
    backgroundColor: '#008000',
    borderRadius: '50%',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  } as CSSProperties,
  buttonPaused: {
    width: 32,
    height: 32,
    backgroundColor: '#CCCC00',
    borderRadius: '50%',
    color: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  } as CSSProperties,
  tableContainer: {

  } as CSSProperties,
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40
  } as CSSProperties,
  cell: {
    width: 160,
    fontSize: 16,
    color: '#414042',
    textAlign: 'center'
  } as CSSProperties,
  cellBolder: {
    width: 260,
    fontSize: 16,
    color: '#414042',
    textAlign: 'center',
    fontWeight: 'bold'
  } as CSSProperties,
  separator: {
    width: 1,
    height: 31,
    backgroundColor: '#414042'
  } as CSSProperties,
  separatorHorizontal: {
    height: 1,
    backgroundColor: '#3B3B3B',
  } as CSSProperties,
  link: {
    color: '#7876E0',
    textDecoration: 'underline',
    cursor: 'pointer'
  } as CSSProperties,
  icon: {
    fontSize: 12,
    color: '#FFF'
  } as CSSProperties,
  noQueuedUpdates: {
    fontSize: 16,
    fontWeight: 500,
  } as CSSProperties,
};

export default styles;