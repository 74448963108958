import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    width: "100%",
    height: 710,
    backgroundColor: "#F4F5F9",
    borderRadius: 3,
    padding: 19,
    margin: 15,
  } as CSSProperties,
  titleBlack: {
    color: "#3B3B3B",
    fontSize: 16,
    fontWeight: "bold",
  } as CSSProperties,

  titlePurple: {
    color: "#7876E0",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
  } as CSSProperties,

  updatesContainers: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 40,
  } as CSSProperties,

  selectionButton: {
    display: "flex",
    justifyContent: "center",
    height: 45,
    background: "#FFF",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 16,
    marginBottom: 16,
    border: "2px solid #7876E0",
  } as CSSProperties,

  selectionButtonSelected: {
    display: "flex",
    justifyContent: "center",
    height: 45,
    background: "#7876E0",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 16,
    marginBottom: 16,
    border: "2px solid #7876E0",
  } as CSSProperties,

  buttonContainer: {
    width: "100%",
  } as CSSProperties,

  buttonConfirm: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#7876E0",
    fontSize: 14,
  } as CSSProperties,

  dateContainer: {
    width: "140px",
    padding: "10px",
    fontWeight: "bold",
    marginBottom: 23,
    backgroundColor: "white",
  } as CSSProperties,
};

export default styles;
