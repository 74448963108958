export const GET_UPDATES = 'updates/GET_UPDATES';
export const GET_UPDATES_SUCCESS = 'updates/GET_UPDATES_SUCCESS';
export const GET_UPDATES_FAILED = 'updates/GET_UPDATES_FAILED';
export const CREATE_UPDATE = 'updates/CREATE_UPDATE';
export const CREATE_UPDATE_SUCCESS = 'updates/CREATE_UPDATE_SUCCESS';
export const CREATE_UPDATE_FAILED = 'updates/CREATE_UPDATE_FAILED';
export const START_ROLLOUT = 'rollouts/START_ROLLOUT';
export const START_ROLLOUT_SUCCESS = 'rollouts/START_ROLLOUT_SUCCESS';
export const START_ROLLOUT_FAILED = 'rollouts/START_UPDATE_FAILED';
export const GET_QUEUED_UPDATES = 'updates/GET_QUEUED_UPDATES';
export const GET_QUEUED_UPDATES_SUCCESS = 'updates/GET_QUEUED_UPDATES_SUCCESS';
export const GET_QUEUED_UPDATES_FAILED = 'updates/GET_QUEUED_UPDATES_FAILED';