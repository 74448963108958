import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppStore } from 'models';
import Styles from './Styles';
import LogoWhite from 'assets/Logo_white.svg';
import { logout } from '../../redux/actions/auth';

export interface TopbarProps extends RouteComponentProps {
  logoutUser: (auth) => void;
  auth: {[key:string]: string}
}

const Topbar: React.FunctionComponent<TopbarProps> = props => {

  const goTo = (page) => {
    props['history'].push(page);
  }

  const handleLogout = () => {
    return props.logoutUser(props.auth)
  }

  return (
    <div style={Styles.mainContainer}>
      <div>
        <img src={LogoWhite} alt='' />
      </div>
      <div style={Styles.linksContainer}>
        <div style={props['match'].url === '/' || props['match'].url === '/schedule-update' ? Styles.linksSelected : Styles.links} onClick={() => goTo('/')}>Dashboard</div>
        <div style={Styles.separator} />
        <div style={props['match'].url === '/machine-list' ? Styles.linksSelected : Styles.links} onClick={() => goTo('/machine-list')}>Machine List</div>
        {/*<div style={Styles.separator}></div>*/}
        {/*<div style={Styles.disabledLink} onClick={() => goTo('/create-package')}>Create Package</div>*/}
        <div style={Styles.separator} />
        <div style={Styles.logout} onClick={() => handleLogout()}>Logout</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppStore) => ({
  
});
const mapDispatchToProps = dispatch => ({
  logoutUser: (auth) => dispatch(logout(auth))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar) as React.ComponentType<any>);