import React, { useState } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { AppStore } from "models";
import Topbar from "components/Topbar";
import Styles from "./Styles";
import SelectRoasterGroup from "components/SelectRoasterGroup";
import SelectUpdate from "components/SelectUpdate";
import SelectedRoasters from "components/SelectedRoasters";

export interface ScheduleUpdateProps extends RouteComponentProps {
  auth: { [key: string]: string };
}

const ScheduleUpdate: React.FunctionComponent<ScheduleUpdateProps> = (
  props
) => {
  const [selectedRoasters, setSelectedRoasters] = useState([]);
  const [unselectedRoasters, setUnselectedRoasters] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const composeSelectedRoaster = (roaster) => {
    const clonedSelectedRoasters = cloneDeep(selectedRoasters);
    const clonedUnselectedRoasters = cloneDeep(unselectedRoasters);
    const deselection = clonedSelectedRoasters.filter((each) => {
      return roaster.controllerId === each.controllerId;
    });
    const filteredClone = clonedSelectedRoasters.filter(
      (each) => each.controllerId !== roaster.controllerId
    );
    const filteredUnselectedRoasters = clonedUnselectedRoasters.filter(
      (each) => each.controllerId !== roaster.controllerId
    );

    if (deselection.length > 0) {
      setUnselectedRoasters(clonedUnselectedRoasters.concat(roaster));
      setSelectedRoasters(filteredClone);
    } else {
      setUnselectedRoasters(filteredUnselectedRoasters);
      setSelectedRoasters(clonedSelectedRoasters.concat(roaster));
    }
  };

  const composeUnselectedRoasters = (roasters) => {
    const composedRoaster = [];

    if (selectedRoasters.length > 0) {
      for (let index = 0; index < roasters.length; index++) {
        for (let j = 0; j < selectedRoasters.length; j++) {
          const selectedRoaster = selectedRoasters[j];
          const fetchedRoaster = roasters[index];
          if (
            selectedRoaster.controllerId !== fetchedRoaster.controllerId &&
            selectedRoasters.filter(
              (each) => each.controllerId === fetchedRoaster.controllerId
            ).length < 1 &&
            composedRoaster.filter(
              (each) => each.controllerId === fetchedRoaster.controllerId
            ).length < 1
          ) {
            composedRoaster.push(fetchedRoaster);
          }
        }
      }

      setUnselectedRoasters(composedRoaster);
    } else {
      setUnselectedRoasters(roasters);
    }
  };

  return (
    <>
      <Topbar auth={props.auth} />
      <div style={Styles.mainContainer}>
        <div style={Styles.subContainer}>
          <SelectedRoasters
            selectedRoasters={selectedRoasters}
            setSelectedRoasters={composeSelectedRoaster}
          />
          <SelectRoasterGroup
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            composeUnselectedRoasters={composeUnselectedRoasters}
            unselectedRoasters={unselectedRoasters}
            setSelectedRoasters={composeSelectedRoaster}
          />
        </div>
        <SelectUpdate selectedRoasters={selectedRoasters} />
      </div>
      <div style={Styles.appVersion}>
        <p>APP_VERSION: {process.env.REACT_APP_VERSION}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({});
const mapDispatchToProps = (dispatch) => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScheduleUpdate) as React.ComponentType<any>
);
