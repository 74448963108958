import { combineReducers, Reducer } from 'redux';
import { AppStore } from 'models/store';
import roasters from './roasters';
import auth from './auth';
import organizations from './organizations';
import groups from './groups';
import updates from './updates';
import distributions from './distributions';


const appState: Reducer<AppStore> = combineReducers({
  auth,
  roasters,
  organizations,
  groups,
  updates,
  distributions
});
export default appState;
