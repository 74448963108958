import { CSSProperties } from 'react';

export const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 30
  } as CSSProperties,
  appVersion: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}

export default styles;