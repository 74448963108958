import React, { Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { AppStore, BaseRoaster, BaseRouterAction } from "models";
import Spinner from "../Spinner";
import Styles from "./Styles";
import { formatDate } from "helpers/formats";

export interface RoasterDetailsProps extends RouteComponentProps {
  roaster: BaseRoaster;
  selectedRoaster: BaseRoaster;
  roasters: BaseRoaster[];
  isLoadingActions: boolean;
  roasterActions: {
    osVersion: string,
    firmwareVersion: string,
    appsVersion: string,
    flattenedRoasters: BaseRouterAction[]
  } | null;
}

const RoasterDetails: React.FunctionComponent<RoasterDetailsProps> = props => {
  props?.roasterActions?.flattenedRoasters.sort((a, b) => b.createdAt - a.createdAt);
  return (
    <>
      {props.isLoadingActions ? (
        <div style={{ display: "flex", height: "80vh" }}>
          <Spinner size={100} />
        </div>
      ) : (
        <div style={Styles.mainContainer}>
          <div style={Styles.title}>
            {props.selectedRoaster?.name} Roaster Details
          </div>
          <div style={Styles.subTitle}>
            Update {props.selectedRoaster?.osVersion}
          </div>
          <div style={Styles.line}></div>
          <div style={Styles.squaresContainer}>
            <div style={Styles.square}>
              <div>OS Version</div>
              <div style={Styles.squareVersion}>
                 {props.roasterActions?.osVersion}
              </div>
            </div>
            <div style={Styles.square}>
              <div>RPI Apps</div>
              <div style={Styles.squareVersion}>
                v. {props.roasterActions?.appsVersion}
              </div>
            </div>
            <div style={Styles.square}>
              <div>Firmware Version</div>
              <div style={Styles.squareVersion}>
                 {props.roasterActions?.firmwareVersion}
              </div>
            </div>
          </div>
          <div style={Styles.subTitle}>Latest Updates</div>
          <div style={Styles.line} />
          <div>
            {props.roasterActions &&
              props.roasterActions?.flattenedRoasters.map(
                (item: { [key: string]: any }, index: number) => {
                  return (
                    <Fragment key={index}>
                      <div style={Styles.row}>
                        <div style={Styles.cell}>
                          {formatDate(item.lastModifiedAt)}
                        </div>
                        <div style={Styles.cell}>{item.distributionSetName}</div>
                        <div style={Styles.cell}>v. {item.distributionSetVersion}</div>
                        <div style={Styles.cell}>{item.status}</div>
                      </div>
                      <div style={Styles.separatorHorizontal} />
                    </Fragment>
                  );
                }
              )}
            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  isLoadingActions: state.roasters.isLoadingActions,
  selectedRoaster: state.roasters.selectedRoaster,
  roasters: state.roasters.roasters,
  roasterActions: state.roasters.roasterActions
});
const mapDispatchToProps = dispatch => ({});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RoasterDetails) as React.ComponentType<any>
);
