import { CSSProperties } from 'react';

export const styles = {
    column: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        flex: 1,
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 24,
    } as CSSProperties,
    details: {
        fontSize: 16,
        fontWeight: 600,
        marginTop: 0,
        color: '#7876E0'
    } as CSSProperties,
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 36,
        marginBottom: 24,
        color: '#3B3B3B'
    } as CSSProperties,
    buttonConfirm: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: '#7876E0',
        fontSize: 14
    } as CSSProperties,
    buttonCancel: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontSize: 14,
        color: '#3B3B3B'
    } as CSSProperties,
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 32
    } as CSSProperties,
    buttonWrapper: {
        width: 245,
    } as CSSProperties,
    inputWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "20px",
    } as CSSProperties,
    input: {
        height: "35px",
        outline: "none",
        textIndent: 10,
        width: "70%",
        fontSize: 14,
        alignItems: "center"
    } as CSSProperties,
};

export default styles;