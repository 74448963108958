import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { AppStore, BaseRoaster } from "models";
import Styles from "./Styles";
import Button from "@atlaskit/button";
import Pagination from "@atlaskit/pagination";
import Textfield from "@atlaskit/textfield";
import { getRoasters } from "redux/actions/roasters";
import Spinner from "../Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export interface SelectRoasterGroupProps extends RouteComponentProps {
  roasters: BaseRoaster[];
  roasterCount: number;
  getRoasters: ({ limit, offset, query }) => void;
  setSelectedRoasters: (selectedRoaster) => void;
  composeUnselectedRoasters: (selectedRoaster) => void;
  unselectedRoasters: BaseRoaster[];
  isLoadingRoasters: boolean;
  searchValue: string;
  setSearchValue: (query) => void
}

const SelectRoasterGroup: React.FunctionComponent<SelectRoasterGroupProps> = (
  props
) => {
  const [Pages, setPages] = useState([]);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 30; // will be hardcoded for now.

  useEffect(() => {
    fetchRoasters("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getRoasters, offset]);

  useEffect(() => {
    const start = props.roasterCount / limit;
    const arr = [];

    if (start >= 1) {
      for (let i = 0; i < start; i++) {
        arr[i] = i + 1;
        setPages(arr);
      }
    } else {
      setPages([1]);
    }
  }, [props.roasterCount]);

  useEffect(() => {
    setStyles();
    // if (props.roasters.length > 0) {
      props.composeUnselectedRoasters(props.roasters);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roasters]);
  
  const setStyles = () => {
    const searchRoasters = document.getElementById("searchRoasters");
    if (searchRoasters) {
      searchRoasters.parentElement.style.height = "23px";
      searchRoasters.parentElement.style.borderTop = "none";
      searchRoasters.parentElement.style.borderLeft = "none";
      searchRoasters.parentElement.style.borderRight = "none";
      searchRoasters.parentElement.style.backgroundColor = "#FFF";
      searchRoasters.parentElement.style.borderColor = "#3B3B3B";
      searchRoasters.parentElement.style.borderRadius = "0px";
    }
  };
  
  const handleChange = (e, page) => {
    const newOffset = limit * (page - 1);
    setOffset(newOffset);
    setCurrentPage(page);
  };
  
  const fetchRoasters = (query) => {
    const getRoasters = props.getRoasters;
    getRoasters({ limit, offset, query });
  }
  
  const handleChangeSearch = (event) => {
    props.setSearchValue(event.target.value);
  };

  const getPaginatedRoasters = () => {
    props.setSearchValue("")
    fetchRoasters( "" );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    
    return fetchRoasters(props.searchValue);
  };

  return (
    <>
      {props.isLoadingRoasters ? (
        <div style={{ display: "flex", height: "100vh", width: "30vw" }}>
          <Spinner size={100} />
        </div>
      ) : (
        <div style={Styles.mainContainer}>
          <div style={Styles.selectRoastersTitleContainer}>
            <div style={Styles.titleBlack}>Select Roasters</div>
            <div style={Styles.searchContainer}>
              <form onSubmit={(event) => handleSubmit(event)}>
                <Textfield
                  id="searchRoasters"
                  type="text"
                  placeholder="Search"
                  onChange={(event) => handleChangeSearch(event)}
                  value={props.searchValue}
                  elemBeforeInput={
                    props.searchValue.length > 0 && (
                      <div style={{ paddingRight: "6px", lineHeight: "100%" }}>
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          style={Styles.icon}
                          onClick={() => getPaginatedRoasters()}
                        />
                      </div>
                    )
                  }
                  elemAfterInput={
                    <div style={{ paddingRight: "6px", lineHeight: "100%" }}>
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={Styles.icon}
                        onClick={(event) => handleSubmit(event)}
                      />
                    </div>
                  }
                />
              </form>
            </div>
          </div>
          <div style={Styles.buttonsCustomContainer}>
            {props.unselectedRoasters.map((item, index) => (
              <Button
                key={index}
                style={Styles.selectionButton}
                onClick={() => props.setSelectedRoasters(item)}
                title={item.name}
                appearance="default"
              >
                {item.name}
              </Button>
            ))}
          </div>
          <Pagination
            pages={Pages}
            onChange={(e, page) => handleChange(e, page)}
            selectedIndex={currentPage - 1}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  roasters: state.roasters.roasters,
  roasterCount: state.roasters.roasterCount,
  isLoadingRoasters: state.roasters.isLoading,
  groups: state.groups.groups,
});
const mapDispatchToProps = (dispatch) => ({
  getRoasters: ({ limit, offset, query }) => dispatch(getRoasters({ limit, offset, query })),
});
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SelectRoasterGroup) as React.ComponentType<any>
);
