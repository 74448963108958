import * as actions from '../constants/auth';
import {OTA} from "../../api";

export const initCognitoSDK = auth => dispatch => {
  dispatch({ type: actions.SIGN_IN });

  OTA.cognito_client_id = auth.clientId;
  OTA.cognito_domain = auth.appWebDomain;

  auth.userhandler = {
    onSuccess: (result) => {
      localStorage.setItem('token', result.getAccessToken().getJwtToken());
      OTA.setHeaders('Authorization', result.getAccessToken().getJwtToken());
      OTA.cognito_refresh_token = result.getRefreshToken().refreshToken;
      console.log("authorised");
      dispatch({ type: actions.SIGN_IN_SUCCESS, data: { result } })
    },
    onFailure: function(err) {
      console.log(err);
      if (JSON.parse(err).error === "invalid_grant") {
        return auth.signOut({ global: true })
      }
      dispatch({ type: actions.SIGN_IN_FAILED, data: { err } })
    }
  };

  auth.useCodeGrantFlow();
};

export const logout = auth => dispatch => {
  dispatch({ type: actions.LOGOUT });

 return auth.signOut({ global: true })
}
