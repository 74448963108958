import React, { useState, Fragment, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { AppStore, BaseRoaster } from "models";
import Styles from "./Styles";
import Textfield from "@atlaskit/textfield";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { orderBy } from "lodash";
import {
  setSelectedRoaster,
  getRoasterActions,
} from "redux/actions/roasters";
import { formatDate } from "helpers/formats";

export interface RoastersTableProps extends RouteComponentProps {
  roasters: BaseRoaster[];
  isSearchResult: boolean;
  fetchRoasters: (query) => void;
  setSelectedRoaster: (selectedRoaster) => void;
  getRoasterActions: (selectedRoasterId) => void;
  setSearchValue: (searchValue) => void;
  searchValue: string;
}

const RoastersTable: React.FunctionComponent<RoastersTableProps> = (props) => {
  const [selectedRoasterId, setSelectedRoasterId] = useState("");
  const [roasters, setRoasters] = useState([]);

  useEffect(() => {
    setStyles();
    setRoasters(props.roasters);
    if (props.roasters.length > 0) {
      handleSelectedRow(props.roasters[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.roasters]);

  const handleSelectedRow = (item) => {
    setSelectedRoasterId(item.controllerId);
    props.setSelectedRoaster(item);
    props.getRoasterActions(item.controllerId);
  };

  const handleChangeSearch = (event) => {
    props.setSearchValue(event.target.value);
  };

  const getPaginatedRoasters = () => {
    props.setSearchValue("")
    props.fetchRoasters( "" );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setSelectedRoasterId("");
    props.setSelectedRoaster(null);
    
    return props.fetchRoasters(props.searchValue);
  };

  const setStyles = () => {
    const searchRoasters = document.getElementById("searchRoasters");
    if (searchRoasters) {
      searchRoasters.parentElement.style.height = "23px";
      searchRoasters.parentElement.style.borderTop = "none";
      searchRoasters.parentElement.style.borderLeft = "none";
      searchRoasters.parentElement.style.borderRight = "none";
      searchRoasters.parentElement.style.backgroundColor = "#FFF";
      searchRoasters.parentElement.style.borderColor = "#3B3B3B";
      searchRoasters.parentElement.style.borderRadius = "0px";
    }
  };

  const sort = (key, direction) => {
    let roastersList = orderBy(roasters, [key], [direction]);
    setRoasters(roastersList);
  };

  return (
    <div style={Styles.mainContainer}>
      <div style={Styles.actionsContainer}>
        <div style={Styles.searchContainer}>
          <form onSubmit={(event) => handleSubmit(event)}>
            <Textfield
              id="searchRoasters"
              type="text"
              placeholder="Search"
              onChange={(event) => handleChangeSearch(event)}
              value={props.searchValue}
              elemBeforeInput={
                props.searchValue.length > 0 && (
                  <div style={{ paddingRight: "6px", lineHeight: "100%" }}>
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      style={Styles.icon}
                      onClick={() => getPaginatedRoasters()}
                    />
                  </div>
                )
              }
              elemAfterInput={
                <div style={{ paddingRight: "6px", lineHeight: "100%" }}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={Styles.icon}
                    onClick={(event) => handleSubmit(event)}
                  />
                </div>
              }
            />
          </form>
        </div>
      </div>
      {props.roasters.length > 0 ? (
        <div style={Styles.tableContainer}>
          <div style={Styles.table}>
            <div style={Styles.header}>
              <div style={Styles.cellHeader}>
                <div style={Styles.cellHeaderContent}>
                  <div>Roaster</div>
                  <div style={Styles.arrowsContainer}>
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={Styles.iconSort}
                      onClick={() => sort("name", "desc")}
                    />
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={Styles.iconSort}
                      onClick={() => sort("name", "asc")}
                    />
                  </div>
                </div>
              </div>
              <div style={Styles.cellHeader}>
                <div style={Styles.cellHeaderContent}>
                  <div>Update Status</div>
                  <div style={Styles.arrowsContainer}>
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={Styles.iconSort}
                      onClick={() => sort("updateStatus", "desc")}
                    />
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={Styles.iconSort}
                      onClick={() => sort("updateStatus", "asc")}
                    />
                  </div>
                </div>
              </div>
              <div style={Styles.cellHeader}>
                <div style={Styles.cellHeaderContent}>
                  <div>Last Update</div>
                  <div style={Styles.arrowsContainer}>
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      style={Styles.iconSort}
                      onClick={() => sort("lastModifiedAt", "desc")}
                    />
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      style={Styles.iconSort}
                      onClick={() => sort("lastModifiedAt", "asc")}
                    />
                  </div>
                </div>
              </div>
            </div>
            {roasters.map((item, index) => (
              <Fragment key={index}>
                <div
                  style={
                    selectedRoasterId === item.controllerId
                      ? Styles.selectedRow
                      : Styles.row
                  }
                  onClick={() => handleSelectedRow(item)}
                >
                  <div style={Styles.cellRoaster}>{item.name}</div>
                  <div style={Styles.cell}>{item.updateStatus}</div>
                  <div style={Styles.cell}>
                    {formatDate(item.lastModifiedAt)}
                  </div>
                </div>
                <div style={Styles.spaceRow} />
              </Fragment>
            ))}
          </div>
        </div>
      ) : (
        <div>
          <p>No match found</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppStore) => ({
  isSearchResult: state.roasters.isSearchResult,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedRoaster: (selectedRoaster) =>
    dispatch(setSelectedRoaster(selectedRoaster)),
  getRoasterActions: (roasterId) => dispatch(getRoasterActions(roasterId)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RoastersTable) as React.ComponentType<any>
);
