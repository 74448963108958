import { CSSProperties } from 'react';

export const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 100, 
    backgroundColor: '#3B3B3B',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 38,
    paddingRight: 38,
  } as CSSProperties,
  linksContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  } as CSSProperties,
  links: {
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#FFF',
    marginLeft: 8,
    marginRight: 8
  } as CSSProperties,
  linksSelected: {
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 'bold',
    color: '#7876E0',
    marginLeft: 8,
    marginRight: 8
  } as CSSProperties,
  separator: {
    backgroundColor: '#FFF',
    width: 1,
    height: 20
  } as CSSProperties,
  logout: {
    cursor: 'pointer',
    fontSize: 18,
    color: 'red',
    marginLeft: 8,
  } as CSSProperties,
}

export default styles;