import React, {useEffect, useState} from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { AppStore } from 'models';
import Topbar from 'components/Topbar';
import Styles from './Styles';
import QueuedUpdates from 'components/QueuedUpdates';
import {getQueuedUpdates} from "../../redux/actions/updates";
import Spinner from "../../components/Spinner";

export interface DashboardProps extends RouteComponentProps {
  getQueuedUpdates: () => void,
  queuedUpdates: [],
  loadingQueuedUpdates: boolean,
  auth: {[key:string]: string}
}

const Dashboard: React.FunctionComponent<DashboardProps> = props => {
  const [scheduledUpdates, setScheduledUpdates] = useState(null);
  const { getQueuedUpdates, queuedUpdates } = props;

  useEffect(() => {
    getQueuedUpdates();
  }, [getQueuedUpdates]);

  useEffect(() => {
    setScheduledUpdates(queuedUpdates);
  }, [queuedUpdates]);

  return (
    <>
      <Topbar auth={props.auth}/>
      {props.loadingQueuedUpdates ?
        <div style={{ display: 'flex', height: '80vh'}}>
          <Spinner size={100} />
        </div> :
        <div style={Styles.mainContainer}>
          <div>Current Updates</div>
          <div>
            <QueuedUpdates scheduledUpdates={scheduledUpdates}/>
          </div>
        </div>
      }
      <div style={Styles.appVersion}>
        <p>APP_VERSION: {process.env.REACT_APP_VERSION}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  queuedUpdates: state.updates.queuedUpdates,
  loadingQueuedUpdates: state.updates.isLoading
});

const mapDispatchToProps = dispatch => ({
  getQueuedUpdates: () => dispatch(getQueuedUpdates()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard) as React.ComponentType<any>);
