import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {toast} from 'react-toastify';
import {AppStore, BaseDistribution, BaseRoaster} from 'models';
import { withRouter, RouteComponentProps } from 'react-router';
import Modal from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import { formatDate } from "../../helpers/formats";
import { createUpdate } from '../../redux/actions/updates';
import Styles from './Styles';
import Spinner from '../Spinner'

export interface ScheduleModalProps extends RouteComponentProps {
  version: any;
  open: boolean;
  selectedUpdate: BaseDistribution;
  selectedGroup: BaseRoaster[];
  selectedDate: string;
  close: () => void;
  isLoading: boolean;
  error: boolean;
  create: boolean;
  createUpdate: (data: { distributionSet: BaseDistribution; name: string; roasters: BaseRoaster[] }) => void;
}

const ScheduleModal: React.FunctionComponent<ScheduleModalProps> = props => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [validatedInput, setValidatedInput] = useState(false);

  useEffect(()=> {
    setOpen(props.open);
  }, [props.open]);

  useEffect(() => {
    if (name.trim().length > 0) {
      return setValidatedInput(true)
    }

    return setValidatedInput(false);
  }, [name])

  useEffect(() => {
    if (props.error) {
      toast.error("Error creating a new rollout")
    }
  }, [props.error])


  useEffect(() => {
    if (props.create) {
      toast.success("Successfully created and started a new rollout")
      setTimeout(() => window.location.assign('/'), 2000)
    }
  }, [props.create])

  const handleConfirm = () => {
    const { createUpdate } = props;
    const data = {
      name: name,
      distributionSet: props.selectedUpdate,
      roasters: props.selectedGroup
    }
    createUpdate(data)
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }

  const handleCancel = () => {
    props.close();
  };

  return (
    <>
      {open &&
      <Modal onClose={() => props.close()} scrollBehavior='outside'>
        <div style={Styles.column}>
          <div style={Styles.title}>Confirmation</div>
        </div>
        <div style={Styles.inputWrapper}>
          <input style={Styles.input} type="text" value={name} onChange={(e) => handleChange(e)} placeholder="Give this rollout a name "/>
        </div>
        <div style={Styles.column}>
          <p>You are about to push an update for the following roasters</p>
          {props.selectedGroup.map(each => {
            return <p key={each.name} style={Styles.details}>{each.name}</p>
          })}
        </div>
        <div style={Styles.column}>
          <p>with update</p>
          <p style={Styles.details}>{props.selectedUpdate.name} {`(${props.selectedUpdate.version})`}</p>
        </div>
        <div style={Styles.column}>
          <p>at</p>
          <p style={Styles.details}>{formatDate(props.selectedDate).replace('-', 'at')}</p>
        </div>
        <div style={Styles.buttonsContainer}>
          <div style={Styles.buttonWrapper}>
            <Button style={Styles.buttonCancel} onClick={handleCancel}>Go Back</Button>
          </div>
          <div style={Styles.buttonWrapper}>
            <Button
                appearance='primary'
                style={(validatedInput || props.isLoading) ? Styles.buttonConfirm : {...Styles.buttonConfirm, backgroundColor: 'rgba(119,118,222,0.69)'}}
                onClick={() => handleConfirm()}
                isDisabled={!validatedInput || props.isLoading}
            >
              {props.isLoading ?
                (<div style={{ display: "flex", backgroundColor: "#f6f6f8", borderRadius: "50%" }}>
                  <Spinner size={25} />
                </div>) : "Confirm"}
            </Button>
          </div>
        </div>
      </Modal>
      }
    </>
  );
};


const mapStateToProps = (state: AppStore) => ({
  isLoading: state.updates.isLoading,
  create: state.updates.create,
  error: state.updates.error
});

const mapDispatchToProps = dispatch => ({
  createUpdate: (data) => dispatch(createUpdate(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduleModal));
