import { AuthReducer } from "../../models";
import * as actions from '../constants/auth';

const initialState: AuthReducer = {
  isLoading: false,
  isAuthenticated: false,
  userInfo: null,
  accessToken: '',
  error: false
};

const roasters = (state: AuthReducer = initialState, action: { type: string, data?: any }) => {
  const { type, data } = action;
  switch(type) {
    case actions.SIGN_IN:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        error: false
      };
    case actions.SIGN_IN_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: data.err
      };
    case actions.SIGN_IN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        userInfo: data.result.getAccessToken().decodePayload(),
        accessToken: data.result.getAccessToken().getJwtToken(),
        error: false
      };
    default:
      return state;
  }
}
export default roasters;
