import { OrganizationsReducer } from "../../models/store";
import * as actions from '../constants/organizations';

const initialState: OrganizationsReducer = {
  isLoading: false,
  organizations: [],
};
const organizations = (state: OrganizationsReducer = initialState, action: { type: string, data?: any }) => {
  const { type, data } = action;
  switch(type) {
    case actions.GET_ORGANIZATIONS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.GET_ORGANIZATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        organizations: [...data.organizations],
      };
    default:
      return state;
  }
}
export default organizations;
