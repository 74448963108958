import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    width: "100%",
    backgroundColor: "#F4F5F9",
    borderRadius: 3,
    padding: 19,
    margin: 15,
  } as CSSProperties,
  selectionButtonSelected: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    height: 40,
    background: "#7876E0",
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 16,
    marginBottom: 18,
    border: "2px solid #7876E0",
  } as CSSProperties,
  titlePurple: {
    color: "#7876E0",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 20,
  } as CSSProperties,
  buttonGroup: {
    display: "flex",
    flexWrap: "wrap",
  } as CSSProperties,
};

export default styles;
