import { RoastersReducer } from "../../models";
import * as actions from "../constants/roasters";

const initialState: RoastersReducer = {
  isLoading: false,
  isLoadingActions: false,
  roasters: [],
  roasterCount: 0,
  selectedRoaster: null,
  roasterActions: null,
  isSearchResult: false,
};
const roasters = (
  state: RoastersReducer = initialState,
  action: { type: string; data?: any }
) => {
  const { type, data } = action;
  switch (type) {
    case actions.GET_ROASTERS:
    case actions.SEARCH_ROASTERS_ACTIONS:
      return {
        ...state,
        isLoading: true,
        selectedRoaster: null,
        isSearchResult: false,
      };
    case actions.GET_ROASTERS_FAILED:
    case actions.SEARCH_ROASTERS_ACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case actions.GET_ROASTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roasterCount: data.data.total,
        roasters: data.data.content,
      };
    case actions.SEARCH_ROASTERS_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        roasterCount: data.data.total,
        roasters: data.data.content,
        isSearchResult: true,
      };
    case actions.SET_SELECTED_ROASTER:
      return {
        ...state,
        selectedRoaster: data.selectedRoaster,
      };
    case actions.GET_ROASTER_ACTIONS:
      return {
        ...state,
        isLoadingActions: true,
        roasterActions: null,
      };
    case actions.GET_ROASTER_ACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingActions: false,
        roasterActions: data.data,
      };
    case actions.GET_ROASTER_ACTIONS_FAILURE:
      return {
        ...state,
        isLoadingActions: false,
      };
    default:
      return state;
  }
};

export default roasters;
