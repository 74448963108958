import { CSSProperties } from "react";

export const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties,
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 25,
    marginBottom: 17,
  } as CSSProperties,
  tableContainer: {
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  } as CSSProperties,
  table: {
    display: "table",
    backgroundColor: "#F4F5F9",
    width: "95%",
    padding: 10,
  } as CSSProperties,
  header: {
    display: "table-row",
  } as CSSProperties,
  cellHeader: {
    display: "table-cell",
    color: "#3B3B3B",
    fontSize: 15,
    fontWeight: "bold",
    height: 40,
    textAlign: "left",
    verticalAlign: "middle",
  } as CSSProperties,
  row: {
    display: "table-row",
    backgroundColor: "#FFF",
    cursor: "pointer",
    color: "#3A3333",
  } as CSSProperties,
  selectedRow: {
    display: "table-row",
    backgroundColor: "#FFF",
    cursor: "pointer",
    color: "#7876E0",
  } as CSSProperties,
  cell: {
    display: "table-cell",
    fontSize: 15,
    height: 40,
    textAlign: "left",
    verticalAlign: "middle",
  } as CSSProperties,
  cellRoaster: {
    display: "table-cell",
    fontSize: 15,
    height: 40,
    textAlign: "left",
    verticalAlign: "middle",
    fontWeight: "bold",
    borderRadius: "5px 0 0 5px",
  } as CSSProperties,
  spaceRow: {
    display: "table-row",
    height: 10,
    backgroundColor: "#F4F5F9",
  } as CSSProperties,
  icon: {
    fontSize: 14,
    color: "#000",
    cursor: "pointer",
  } as CSSProperties,
  searchContainer: {
    width: 194,
  } as CSSProperties,
  cellHeaderContent: {
    display: "flex",
    flexDirection: "row",
  } as CSSProperties,
  iconSort: {
    fontSize: 12,
    color: "#000",
    cursor: "pointer",
  } as CSSProperties,
  arrowsContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
  } as CSSProperties,
  lastCell: {
    display: "table-cell",
    fontSize: 15,
    height: 40,
    textAlign: "center",
    verticalAlign: "middle",
    borderRadius: "0 5px 5px 0",
  } as CSSProperties,
  separator: {
    width: 1,
    height: 31,
    backgroundColor: "#414042",
  } as CSSProperties,
  headerSeparator: {
    width: 1,
    height: 31,
  } as CSSProperties,
};

export default styles;
