export const GET_ROASTERS = "roasters/GET_ROASTERS";
export const GET_ROASTERS_SUCCESS = "roasters/GET_ROASTERS_SUCCESS";
export const GET_ROASTERS_FAILED = "roasters/GET_ROASTERS_FAILED";
export const SET_SELECTED_ROASTER = "roasters/SET_SELECTED_ROASTER";
export const GET_ROASTER_ACTIONS = "roasters/GET_ROASTER_ACTIONS";
export const GET_ROASTER_ACTIONS_SUCCESS =
  "roaster/GET_ROASTER_ACTIONS_SUCCESS";
export const GET_ROASTER_ACTIONS_FAILURE =
  "roaster/GET_ROASTER_ACTIONS_FAILURE";
export const SEARCH_ROASTERS_ACTIONS = "roasters/SEARCH_ROASTERS_ACTIONS";
export const SEARCH_ROASTERS_ACTIONS_SUCCESS =
  "roaster/SEARCH_ROASTERS_ACTIONS_SUCCESS";
export const SEARCH_ROASTERS_ACTIONS_FAILURE =
  "roaster/SEARCH_ROASTERS_ACTIONS_FAILURE";
