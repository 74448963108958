import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import {AppStore, BaseAuth, UserInfo} from 'models';
import Dashboard from 'containers/Dashboard';
import MachineList from 'containers/MachineList';
import CreatePackage from 'containers/CreatePackage';
import ScheduleUpdate from 'containers/ScheduleUpdate';
import Spinner from "../../components/Spinner";
import { OTA } from '../../api';
import { initCognitoSDK } from "../../redux/actions/auth";

export interface AppProps {
  initCognitoSDK(auth: BaseAuth): string,
  isLoading: boolean,
  isAuthenticated: boolean,
  userInfo: UserInfo,
  accessToken: string,
}

toast.configure() // configure toast

const App: React.FunctionComponent<AppProps> = props => {
  const [auth, setAuth] = useState<BaseAuth | null>(null);
  const { initCognitoSDK, isAuthenticated } = props;

  useEffect(() => {
    if (!isAuthenticated && !auth) {
      OTA.get('/auth/cognitoConfig?app=ota&user_pool=bellwether', { baseURL: process.env.REACT_APP_BW_ADMIN_URL })
        .then(data => {
          data["RedirectUriSignIn"] = `${window.location.protocol}//${window.location.host}/`;
          data["RedirectUriSignOut"] = `${window.location.protocol}//${window.location.host}/`;

          setAuth(new CognitoAuth(data));
        })
        .catch(error => console.log(error, 'error'));
    }
  }, [isAuthenticated, auth]);


  useEffect(() => {
    if(!isAuthenticated && auth){
      initCognitoSDK(auth);

      const currentUrl = window.location.href;
      auth?.parseCognitoWebResponse(currentUrl);
      if((currentUrl.search(/state=([^&#]*)/i) < 0 || currentUrl.search(/code=([^&#]*)/i) < 0)){
        auth?.getSession();
      }
    }

  }, [isAuthenticated, auth, initCognitoSDK]);

  return (
    <>
      {props.isLoading || !isAuthenticated || auth === null?
        <div style={{ display: 'flex', height: '100vh'}}>
          <Spinner size={100} />
        </div> :
        <Router>
          <Switch>
            <Route exact component={()=> <Dashboard auth={auth}/>} path="/" />
            <Route exact component={()=> <MachineList auth={auth}/>} path="/machine-list" />
            <Route exact component={()=> <CreatePackage auth={auth}/>} path="/create-package" />
            <Route exact component={()=> <ScheduleUpdate auth={auth}/>} path="/schedule-update" />
          </Switch>
        </Router>
      }
    </>
  );
};

const mapStateToProps = (state: AppStore) => ({
  isLoading: state.auth.isLoading,
  isAuthenticated:  state.auth.isAuthenticated,
  userInfo: state.auth.userInfo,
  accessToken: state.auth.accessToken,
});

const mapDispatchToProps = dispatch => ({
  initCognitoSDK: (auth) => dispatch(initCognitoSDK(auth))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
